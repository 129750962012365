<template>
    <div>
        <!-- New cost -->

        <v-data-table
            :loading="loading"
            :headers="headers"
            class="elevation-0 mt-8"
            :items="localCalculation.items"
            :mobile-breakpoint="0"
        >
            <template v-slot:top>
                <v-toolbar flat style="background-color: #eeeeee !important">
                    <v-toolbar-title>ITEMS</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        depressed
                        color="primary"
                        @click="openAddItem = true"
                        rounded
                        class="ml-7"
                    >
                        <v-icon left>mdi-plus</v-icon>
                        <span>New</span>
                    </v-btn>
                </v-toolbar>
            </template>

            <template v-slot:[`item.row`]="{ item }">
                {{ item.index }}
            </template>
            <template v-slot:[`item.width`]="{ item }">
                {{ item.width }}
            </template>
            <template v-slot:[`item.height`]="{ item }">
                {{ item.height }}
            </template>
            <template v-slot:[`item.quantity`]="{ item }">
                {{ item.quantity }}
            </template>
            <template v-slot:[`item.unitArea`]="{ item }">
                {{ ((item.width * item.height) / 144).toFixed(2) }}
            </template>
            <template v-slot:[`item.itemArea`]="{ item }">
                {{
                    (
                        ((item.width * item.height) / 144) *
                        item.quantity
                    ).toFixed(2)
                }}
            </template>
            <template v-slot:[`item.unitPV`]="{ item }">
                {{ unitPV(item).toFixed(1) }}
            </template>
            <template v-slot:[`item.itemPV`]="{ item }">
                {{ (unitPV(item) * item.quantity).toFixed(1) }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon small @click="showEditForm(item.index)">
                    <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon small @click="showDeleteForm(item.index)">
                    <v-icon small>mdi-trash-can</v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <v-simple-table dense class="my-4">
            <template v-slot:top>
                <v-toolbar flat style="background-color: #eeeeee !important">
                    <v-toolbar-title>PRICING</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </template>
            <template v-slot:default>
                <thead class="grey lighten-3">
                    <tr>
                        <th class="text-center no-border">
                            MATERIALS
                        </th>
                        <th class="text-center no-border">
                            PAINT
                        </th>
                        <th class="text-center no-border">OTHERS</th>
                        <th class="text-center no-border">
                            ASSAMBLY
                        </th>
                        <th class="text-center no-border">
                            PACKAGING
                        </th>
                        <th class="text-center no-border">TOTAL</th>
                        <th class="text-center no-border">
                            MARGIN
                        </th>
                        <th class="text-center no-border">
                            SALE
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-center">
                            {{
                                new Intl.NumberFormat('de-DE').format(
                                    materialsPrice.toFixed(2)
                                )
                            }}
                            {{ localCalculation.currency }}
                        </td>
                        <td class="text-center">
                            {{
                                new Intl.NumberFormat('de-DE').format(
                                    paintPrice.toFixed(2)
                                )
                            }}
                            {{ localCalculation.currency }}
                        </td>
                        <td class="text-center">
                            {{
                                new Intl.NumberFormat('de-DE').format(
                                    otherPrice.toFixed(2)
                                )
                            }}
                            {{ localCalculation.currency }}
                        </td>
                        <td class="text-center">
                            {{
                                new Intl.NumberFormat('de-DE').format(
                                    assamblyPrice.toFixed(2)
                                )
                            }}
                            {{ localCalculation.currency }}
                        </td>
                        <td class="text-center">
                            {{
                                new Intl.NumberFormat('de-DE').format(
                                    packagePrice.toFixed(2)
                                )
                            }}
                            {{ localCalculation.currency }}
                        </td>
                        <td class="text-center">
                            {{
                                new Intl.NumberFormat('de-DE').format(
                                    total.toFixed(2)
                                )
                            }}
                            {{ localCalculation.currency }}
                        </td>
                        <td class="text-center">
                            {{
                                new Intl.NumberFormat('de-DE').format(
                                    margin.toFixed(2)
                                )
                            }}
                            {{ localCalculation.currency }}
                        </td>
                        <td class="text-center">
                            {{
                                new Intl.NumberFormat('de-DE').format(
                                    sale.toFixed(2)
                                )
                            }}
                            {{ localCalculation.currency }}
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-simple-table dense>
            <template v-slot:top>
                <v-toolbar flat style="background-color: #eeeeee !important">
                    <v-toolbar-title>INDICATORS</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </template>
            <template v-slot:default>
                <thead class="grey lighten-3">
                    <tr>
                        <th class="text-center no-border">
                            WEIGHT (lb)
                        </th>
                        <th class="text-center no-border">
                            AREA (sqft)
                        </th>
                        <th class="text-center no-border">
                            COST/WEIGHT
                        </th>
                        <th class="text-center no-border">
                            SP/WEIGHT
                        </th>
                        <th class="text-center no-border">
                            COST/AREA
                        </th>
                        <th class="text-center no-border">
                            SP/AREA
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-center">
                            {{
                                new Intl.NumberFormat('de-DE').format(
                                    weight.toFixed(1)
                                )
                            }}
                            lb
                        </td>
                        <td class="text-center">
                            {{
                                new Intl.NumberFormat('de-DE').format(
                                    area.toFixed(1)
                                )
                            }}
                            sqft
                        </td>
                        <td class="text-center">
                            {{
                                new Intl.NumberFormat('de-DE').format(
                                    weightCost.toFixed(2)
                                )
                            }}
                            {{ localCalculation.currency }}/lb
                        </td>
                        <td class="text-center">
                            {{
                                new Intl.NumberFormat('de-DE').format(
                                    weightPV.toFixed(2)
                                )
                            }}
                            {{ localCalculation.currency }}/lb
                        </td>
                        <td class="text-center">
                            {{
                                new Intl.NumberFormat('de-DE').format(
                                    areaCost.toFixed(2)
                                )
                            }}
                            {{ localCalculation.currency }}/sqft
                        </td>
                        <td class="text-center">
                            {{
                                new Intl.NumberFormat('de-DE').format(
                                    areaPV.toFixed(2)
                                )
                            }}
                            {{ localCalculation.currency }}/sqft
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <!-- item dialog -->
        <v-dialog
            :retain-focus="false"
            v-model="openAddItem"
            persistent
            max-width="640px"
        >
            <v-card v-if="openAddItem">
                <v-card-title class="text-h5">ADD ITEM</v-card-title>
                <v-card-text>
                    <v-form v-model="validNewItem" ref="form">
                        <v-row no-gutters class="mt-3">
                            <v-col
                                cols="12"
                                xl="4"
                                lg="4"
                                md="4"
                                sm="12"
                                class="d-flex align-center"
                            >
                                <v-text-field
                                    v-model="newItem.width"
                                    :rules="[rules.required, rules.width]"
                                    class="py-0 my-0"
                                    prepend-icon="mdi-ruler"
                                    label="Width (in)*"
                                    required
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                xl="4"
                                lg="4"
                                md="4"
                                sm="12"
                                class="d-flex align-center"
                            >
                                <v-text-field
                                    v-model="newItem.height"
                                    :rules="[rules.required, rules.height]"
                                    class="py-0 my-0"
                                    prepend-icon="mdi-ruler"
                                    label="Height (in)*"
                                    required
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                xl="4"
                                lg="4"
                                md="4"
                                sm="12"
                                class="d-flex align-center"
                            >
                                <v-text-field
                                    v-model="newItem.quantity"
                                    :rules="[rules.posInt]"
                                    class="py-0 my-0"
                                    prepend-icon="mdi-package-variant-closed-plus"
                                    label="Quantity (units)*"
                                    required
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-row no-gutters class="mx-3 mb-3">
                        <v-col cols="12" class="d-flex">
                            <v-btn
                                text
                                color="secondary"
                                @click="openAddItem = false"
                            >
                                CANCEL
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                @click="addItem"
                                rounded
                                color="primary"
                                :loading="newItemLoading"
                                :disabled="!validNewItem"
                            >
                                CREATE
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- delete item -->

        <v-dialog
            :retain-focus="false"
            v-model="openDeleteDialog"
            persistent
            max-width="400px"
        >
            <v-card>
                <v-card-title class="text-h5">Delete Item</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this item?
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="openDeleteDialog = false"
                    >
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="editLoading"
                        @click="deleteItem"
                        text
                        color="error"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- update item -->

        <v-dialog
            :retain-focus="false"
            v-model="openEditDialog"
            persistent
            max-width="640px"
        >
            <v-card>
                <v-card-title class="text-h5">Update item</v-card-title>
                <v-card-text>
                    <v-form v-model="validNewItem" ref="form">
                        <v-row no-gutters class="mt-3">
                            <v-col
                                cols="12"
                                xl="4"
                                lg="4"
                                md="4"
                                sm="12"
                                class="d-flex align-center"
                            >
                                <v-text-field
                                    v-model="newItem.width"
                                    :rules="[rules.required, rules.width]"
                                    class="py-0 my-0"
                                    prepend-icon="mdi-ruler"
                                    label="Width (in)*"
                                    required
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                xl="4"
                                lg="4"
                                md="4"
                                sm="12"
                                class="d-flex align-center"
                            >
                                <v-text-field
                                    v-model="newItem.height"
                                    :rules="[rules.required, rules.height]"
                                    class="py-0 my-0"
                                    prepend-icon="mdi-ruler"
                                    label="Height (in)*"
                                    required
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                xl="4"
                                lg="4"
                                md="4"
                                sm="12"
                                class="d-flex align-center"
                            >
                                <v-text-field
                                    v-model="newItem.quantity"
                                    :rules="[rules.posInt]"
                                    class="py-0 my-0"
                                    prepend-icon="mdi-package-variant-closed-plus"
                                    label="Quantity (units)*"
                                    required
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="openEditDialog = false"
                    >
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="editLoading"
                        @click="editItem"
                        text
                        color="error"
                    >
                        Update
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import API from '@/services/api'
import _ from 'lodash'

export default {
    name: 'CalculationInputs',
    components: {
        // SendQuote,
    },
    props: {
        id: String,
        calculation: {
            type: Object,
            default() {
                return {}
            },
        },
        settings: Array,
    },
    data() {
        return {
            height: 0,
            loading: false,
            error: null,
            localCalculation: {},
            weight: 0,
            area: 0,
            weightCost: 0,
            weightPV: 0,
            areaCost: 0,
            areaPV: 0,
            materialsPrice: 0,
            paintPrice: 0,
            otherPrice: 0,
            assamblyPrice: 0,
            packagePrice: 0,
            total: 0,
            margin: 0,
            sale: 0,
            openAddItem: false,
            editDialog: false,
            newItemLoading: false,
            openDeleteDialog: false,
            openEditDialog: false,
            editLoading: false,
            validNewItem: true,
            selectedIndex: null,
            newItem: { width: '', height: '', quantity: '' },
            headers: [
                {
                    text: 'ITEM',
                    value: 'index',
                    class: 'grey lighten-3',
                },
                {
                    text: 'WIDTH (in)',
                    value: 'width',
                    class: 'grey lighten-3',
                },
                {
                    text: 'HEIGHT (in)',
                    value: 'height',
                    class: 'grey lighten-3',
                },
                {
                    text: 'QUANTITY',
                    value: 'quantity',
                    class: 'grey lighten-3',
                },
                {
                    text: 'UNIT AREA (sqft)',
                    value: 'unitArea',
                    class: 'grey lighten-3',
                },
                {
                    text: 'ITEM AREA (sqft)',
                    value: 'itemArea',
                    class: 'grey lighten-3',
                },
                {
                    text: 'UNIT SP (USD)',
                    value: 'unitPV',
                    class: 'grey lighten-3',
                },
                {
                    text: 'ITEM SP (USD)',
                    value: 'itemPV',
                    class: 'grey lighten-3',
                },
                {
                    text: 'ACTION',
                    value: 'actions',
                    sortable: false,
                    align: 'center',
                    class: 'grey lighten-3',
                },
            ],
            rules: {
                required: v => !!v || 'Required',
                posInt: v =>
                    (!!v && Number.isInteger(Number(v)) && Number(v) > 0) ||
                    'The value must be a positive integer number',

                width: v =>
                    (v > 0 &&
                        v <= 120 &&
                        (!this.newItem.height || this.newItem.height < 120)) ||
                    (v > 0 &&
                        v <= 60 &&
                        (!this.newItem.height || this.newItem.height == 120)) ||
                    'Enter a value that complies with valid measurements',
                height: v =>
                    (v > 0 &&
                        v <= 120 &&
                        (!this.newItem.width || this.newItem.width < 120)) ||
                    (v > 0 &&
                        v <= 60 &&
                        (!this.newItem.width || this.newItem.width == 120)) ||
                    'Enter a value that complies with valid measurements',
            },
        }
    },
    inject: {
        replaceCalculation: {
            from: 'replaceCalculation',
        },
    },
    watch: {
        openDeleteDialog: async function(state) {
            if (!state) this.selectedItem = null
        },
        openEditDialog: async function(state) {
            if (!state) this.selectedItem = null
        },
    },
    computed: {
        unitArea: function(object) {
            return object.width * object.height
        },
        ...mapState(['saveBtn']),
        ...mapGetters(['getAlternatives']),
    },
    async created() {
        try {
            this.localCalculation = this.calculation
            this.setTotalWeight()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        unitPV(item) {
            const unitArea = (item.width * item.height) / 144 //Unit area in sqft
            //const itemArea = unitArea * item.quantity //Item area in sqft
            let kWA =
                unitArea > this.localCalculation.areas.large
                    ? this.localCalculation.weights.large
                    : unitArea > this.localCalculation.areas.medium
                    ? this.localCalculation.weights.large
                    : unitArea > this.localCalculation.areas.small
                    ? this.localCalculation.weights.medium
                    : this.localCalculation.weights.small

            const weight = kWA * unitArea
            const unitWaste =
                weight / (1 - this.localCalculation.waste / 100) - weight
            const totalWeight = (weight + unitWaste) * item.quantity

            const materialCosts =
                totalWeight * this.localCalculation.materialPrice
            const paintCost = totalWeight * this.localCalculation.paintPrice

            const kMaterials =
                this.weight > 20000
                    ? this.localCalculation.materials.large
                    : this.weight > 10000
                    ? this.localCalculation.materials.medium
                    : this.localCalculation.materials.small
            let otherMaterials = kMaterials * totalWeight

            const kAssambly =
                this.weight > 20000
                    ? this.localCalculation.assambly.large
                    : this.weight > 10000
                    ? this.localCalculation.assambly.medium
                    : this.localCalculation.assambly.small
            const assambly = kAssambly * totalWeight

            const kPackages =
                this.weight > 20000
                    ? this.localCalculation.packages.large
                    : this.weight > 10000
                    ? this.localCalculation.packages.medium
                    : this.localCalculation.packages.small
            const packages = kPackages * totalWeight

            const totalCost =
                materialCosts + paintCost + otherMaterials + assambly + packages

            const unitPV =
                totalCost /
                (1 - this.localCalculation.margin / 100) /
                item.quantity

            return unitPV
        },
        setTotalWeight() {
            if (!this.localCalculation.items) return
            let totalPV = 0
            let materialsPrice = 0
            let paintPrice = 0
            let otherPrice = 0
            let assamblyPrice = 0
            let packagePrice = 0
            let total = 0
            let weight = 0
            let area = 0
            this.localCalculation.items.forEach((item, index) => {
                item.index = index + 1
                const unitArea = (item.width * item.height) / 144 //Unit area in sqft
                let kWA =
                    unitArea > this.localCalculation.areas.large
                        ? this.localCalculation.weights.large
                        : unitArea > this.localCalculation.areas.medium
                        ? this.localCalculation.weights.large
                        : unitArea > this.localCalculation.areas.small
                        ? this.localCalculation.weights.medium
                        : this.localCalculation.weights.small
                const weightArea = kWA * unitArea
                const unitWaste =
                    weightArea / (1 - this.localCalculation.waste / 100) -
                    weightArea
                weight += (weightArea + unitWaste) * item.quantity
            })
            this.localCalculation.items.forEach((item, index) => {
                item.index = index + 1
                const unitArea = (item.width * item.height) / 144 //Unit area in sqft
                const itemArea = unitArea * item.quantity //Item area in sqft
                let kWA =
                    unitArea > this.localCalculation.areas.large
                        ? this.localCalculation.weights.large
                        : unitArea > this.localCalculation.areas.medium
                        ? this.localCalculation.weights.large
                        : unitArea > this.localCalculation.areas.small
                        ? this.localCalculation.weights.medium
                        : this.localCalculation.weights.small
                const weightArea = kWA * unitArea
                const unitWaste =
                    weightArea / (1 - this.localCalculation.waste / 100) -
                    weightArea
                const totalWeight = (weightArea + unitWaste) * item.quantity

                const materialCosts =
                    totalWeight * this.localCalculation.materialPrice
                const paintCost = totalWeight * this.localCalculation.paintPrice

                const kMaterials =
                    weight > 20000
                        ? this.localCalculation.materials.large
                        : weight > 10000
                        ? this.localCalculation.materials.medium
                        : this.localCalculation.materials.small
                let otherMaterials = kMaterials * totalWeight

                const kAssambly =
                    weight > 20000
                        ? this.localCalculation.assambly.large
                        : weight > 10000
                        ? this.localCalculation.assambly.medium
                        : this.localCalculation.assambly.small
                const assambly = kAssambly * totalWeight

                const kPackages =
                    weight > 20000
                        ? this.localCalculation.packages.large
                        : weight > 10000
                        ? this.localCalculation.packages.medium
                        : this.localCalculation.packages.small
                const packages = kPackages * totalWeight

                const totalCost =
                    materialCosts +
                    paintCost +
                    otherMaterials +
                    assambly +
                    packages

                totalPV +=
                    totalCost /
                    (1 - this.localCalculation.margin / 100) /
                    item.quantity

                materialsPrice += materialCosts
                paintPrice += paintCost
                otherPrice += otherMaterials
                assamblyPrice += assambly
                packagePrice += packages
                total += totalCost
                area += itemArea
            })
            this.materialsPrice = materialsPrice
            this.paintPrice = paintPrice
            this.otherPrice = otherPrice
            this.assamblyPrice = assamblyPrice
            this.packagePrice = packagePrice
            this.total = total
            this.weight = weight
            this.area = area
            this.margin = this.total / (1 - this.localCalculation.margin / 100)
            this.sale = this.total + this.margin
            this.weightCost = this.total / this.weight
            this.weightPV = totalPV / this.weight
            this.areaCost = this.total / this.area
            this.areaPV = totalPV / this.area
        },
        async addItem() {
            try {
                this.newItemLoading = true
                const item = _.cloneDeep(this.newItem)
                if (this.localCalculation.items)
                    this.localCalculation.items.push(item)
                else this.localCalculation.items = [item]
                const items = { items: this.localCalculation.items }
                await API.updateCalculation(this.id, items)
                this.setTotalWeight()
                this.localCalculation = _.cloneDeep(this.localCalculation)
                this.calculation = this.localCalculation
                this.$root.$emit('replaceCalculation', this.localCalculation)
                this.newItem = { width: '', height: '', quantity: '' }
                this.newItemLoading = false
                this.openAddItem = false
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        showEditForm(index) {
            this.selectedIndex = index
            this.newItem = this.localCalculation.items[index - 1]
            this.openEditDialog = true
        },
        showDeleteForm(index) {
            this.selectedIndex = index
            this.openDeleteDialog = true
        },
        async editItem() {
            if (!this.selectedIndex) return
            this.editLoading = true
            try {
                let items = _.cloneDeep(this.localCalculation.items)
                items[this.selectedIndex - 1] = this.newItem
                await API.updateCalculation(this.id, { items })
                this.editLoading = false
                this.localCalculation.items = items
                this.calculation = this.localCalculation
                this.setTotalWeight()
                this.newItem = { width: '', height: '', quantity: '' }
                this.$root.$emit('replaceCalculation', this.localCalculation)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
                this.editLoading = false
            }
            this.openEditDialog = false
        },
        async deleteItem() {
            if (!this.selectedIndex) return
            this.editLoading = true
            try {
                let items = _.cloneDeep(this.localCalculation.items)
                items.forEach(item => {
                    delete item.index
                })
                items.splice(this.selectedIndex - 1, 1)
                await API.updateCalculation(this.id, { items })
                items.forEach((item, index) => {
                    item.index = index + 1
                })
                this.editLoading = false
                this.localCalculation.items = items
                this.calculation = this.localCalculation
                this.setTotalWeight()
                this.$root.$emit('replaceCalculation', this.localCalculation)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
                this.editLoading = false
            }
            this.openDeleteDialog = false
        },
    },
}
</script>
